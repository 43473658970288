
  import Vue, { PropType } from 'vue';
  import * as api from '@/api';

  /**
   * 纯组件
   */
  export default Vue.extend({
    name: 'TypeSelectorTree',
    props: {
      value: {
        type: Array as PropType<api.ProductTypeBase['type'][]>,
        required: true,
      },
      max: {
        type: Number,
        default: 2,
      },
      forest: {
        type: Array as PropType<api.ProductTypeTreeNode[]>,
        required: true,
      },
    },
    data() {
      return {
        typeTreeData: {} as api.ProductTypeTreeNode[],
      };
    },
    methods: {
      handleTypeTreeCheck(_, { checkedKeys }) {
        this.$emit('input', checkedKeys);
      },
    },
  });
