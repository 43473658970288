
  import Vue from 'vue';
  import * as api from '@/api';
  import TypeSelector from '@/components/TypeSelector.vue';

  export default Vue.extend({
    name: 'ProductType',
    components: { TypeSelector },
    data() {
      return {
        isExpanded: false,
        loading: false,
        filterText: '',
        typeAddParent: null as api.ProductTypeTreeNode | null,
        typeAddVisible: false,
        typeAddForm: {
          name: '',
        },
        typeTreeData: [] as api.ProductTypeTreeNode[],
        currentType: null as api.ProductTypeTreeNode | null,
        inputingName: '',
        inputingDescription: '',
        transferVisible: false,
        transferTypeIds: [] as string[],
      };
    },
    computed: {
      typeTreeMap(): { [key: string]: api.ProductTypeTreeNode } {
        const ret = Object.create(null);
        function dfs(node: api.ProductTypeTreeNode) {
          if (!node) return;
          ret[node.id] = node;
          node.children.forEach((child) => dfs(child));
        }
        this.typeTreeData.forEach((tree) => {
          dfs(tree);
        });
        return ret;
      },
    },
    watch: {
      filterText(val) {
        (this.$refs.tree as any).filter(val);
      },
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '分类管理',
          path: 'classification',
        },
      ]);
      await this.fetchTypeTreeData();
    },
    methods: {
      filterTreeNode(value, data: api.ProductTypeTreeNode) {
        if (!value) return true;
        return data.txt.indexOf(value) !== -1;
      },
      async fetchTypeTreeData() {
        this.loading = true;
        const res = await api.getProductTypeTree();
        if (!api.error(res)) {
          this.typeTreeData = res.data.roots;
          this.resetCurrentType();
        }
        this.loading = false;
      },
      resetCurrentType() {
        this.currentType = this.typeTreeData[0];
        this.inputingName = this.currentType.txt;
      },
      addType(parent) {
        this.typeAddParent = parent;
        this.typeAddVisible = true;
      },
      removeType(type: api.ProductTypeTreeNode) {
        this.$confirm('该分类下所有分类，将会—起删除', '你确定要删除该分类组吗?', {
          confirmButtonText: '确定删除',
          confirmButtonClass: 'bg-danger',
          cancelButtonText: '取消',
          // type: 'error',
          iconClass: 'el-icon-question',
        }).then(async () => {
          const res = await api.deleteProductType({ id: type.id });
          if (!api.error(res)) {
            this.$message({
              type: 'success',
              message: '删除成功',
            });
            this.resetCurrentType();
          } else {
            this.$message({
              type: 'error',
              message: res.error.message,
            });
          }
          await this.fetchTypeTreeData(); // todo: 会重置整棵树的展开状态
        });
      },
      async doAddType() {
        const res = await api.createProductType({
          parentId: this.typeAddParent ? this.typeAddParent.id : '0',
          name: this.typeAddForm.name,
        });
        if (!api.error(res)) {
          this.$message({
            type: 'success',
            message: '添加成功',
          });
          this.typeAddVisible = false;
          this.typeAddForm.name = '';
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
        await this.fetchTypeTreeData(); // todo: 会重置整棵树的展开状态
      },
      async doTransferType() {
        if (!this.currentType) return;
        const res = await api.transferProductType({
          sourceId: this.currentType.id,
          targetTypeIds: this.transferTypeIds,
        });
        if (!api.error(res)) {
          this.$message({
            type: 'success',
            message: '转移成功',
          });
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
      },
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
        // eslint-disable-next-line no-underscore-dangle
        const nodes = (this.$refs.tree as any).store._getAllNodes();
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].data.id < 100) {
            nodes[i].expanded = this.isExpanded;
          }
        }
      },
      handleClickType(type: api.ProductTypeTreeNode) {
        this.currentType = type;
        this.inputingName = type.txt;
        this.inputingDescription = type.description;
      },
      async editTypeName(type: api.ProductTypeTreeNode, value: string) {
        if (value === type.txt) return;
        if (value.length > 6) {
          this.$message({
            type: 'error',
            message: '名称不超过6个字符',
          });
          return;
        }
        const res = await api.editProductType({
          id: type.id,
          name: value,
          isShow: type.isShow,
          description: type.description,
        });
        if (!api.error(res)) {
          type.txt = value;
          this.$message({ type: 'success', message: '修改成功' });
        } else {
          this.$message({ type: 'error', message: res.error.message });
        }
      },
      async editTypeIsShow(type: api.ProductTypeTreeNode, value: boolean) {
        if (value === type.isShow) return;
        const res = await api.editProductType({
          id: type.id,
          name: type.txt,
          isShow: value,
          description: type.description,
        });
        if (!api.error(res)) {
          type.isShow = value;
          this.$message({ type: 'success', message: '修改成功' });
        } else {
          this.$message({ type: 'error', message: res.error.message });
        }
      },
      async editTypeDescription(type: api.ProductTypeTreeNode, value: string) {
        if (value === type.description) return;
        const res = await api.editProductType({
          id: type.id,
          name: type.txt,
          isShow: type.isShow,
          description: value,
        });
        if (!api.error(res)) {
          type.description = value;
          this.$message({ type: 'success', message: '修改成功' });
        } else {
          this.$message({ type: 'error', message: res.error.message });
        }
      },
      async navToProductList(type: api.ProductTypeTreeNode) {
        const res = await api.productTypeViewProduct({ id: type.id });
        if (!api.error(res)) {
          if (res.data.productListCount > 0) {
            if (res.data.recycledCount > 0) {
              this.$message.info('部分产品在回收站内');
            }
            this.$router.push({
              name: 'ProductList',
              query: {
                type: type.id,
              },
            });
          } else {
            this.$router.push({
              name: 'RecycleBin',
              query: {
                type: type.id,
              },
            });
          }
        }
      },
    },
  });

