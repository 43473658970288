
  import Vue, { PropType } from 'vue';
  import * as api from '@/api';
  // import TypeSelectorHistory from './TypeSelectorHistory.vue';
  import TypeSelectorTree from './TypeSelectorTree.vue';
  // import TypeSelectorTags from './TypeSelectorTags.vue';

  /**
   * 去掉api.getProductTypeTree()的话，就是纯组件了
   */
  export default Vue.extend({
    name: 'TypeSelector',
    components: { TypeSelectorTree },
    props: {
      value: {
        type: Array as PropType<string[]>,
        required: true,
      },
      types: {
        type: Array as PropType<any[]>,
        required: false,
      },
    },
    data() {
      return {
        selectedIds: [] as string[],
        typeForestData: [] as api.ProductInfoTypeTreeNode[],
      };
    },
    computed: {
      typeMapById(): { [id: number]: api.ProductInfoTypeTreeNode } {
        const ret = Object.create(null);
        function dfs(node: api.ProductInfoTypeTreeNode) {
          ret[node.id] = node;
          node.children.forEach((child) => {
            dfs(child);
          });
        }
        this.typeForestData.forEach((tree) => dfs(tree));
        return ret;
      },
    },
    watch: {
      selectedIds(value) {
        this.$emit('input', [...value]);
        this.$emit('update:types', value.map((id) => this.typeMapById[id]));
      },
    },
    async created() {
      this.selectedIds = [...this.value];
      const res = await api.getProductInfoTypeTree();
      if (!api.error(res)) {
        this.typeForestData = res.data.roots;
      }
    },
  });
